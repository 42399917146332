import { Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material'
import anylogger from '@app/anylogger'
import { useCallback } from 'react'

const log = anylogger('DataTable')

interface DataTableProps {
	data: string[]
	columns: number
	centerCells?: boolean
}
export function DataTable(props: DataTableProps, ref: any) {
	const { data, columns = 2, centerCells } = props
	const theme = useTheme()
	const getCols = useCallback(
		(rowIdx: number) => {
			let cols = []
			for (let colIdx = 0; colIdx < columns; colIdx++) {
				const str = colIdx + rowIdx < data.length ? data[rowIdx + colIdx] : ''
				let props: any = {}
				if (centerCells) props = { ...props, textAlign: 'center' }

				cols.push(
					<TableCell key={colIdx} sx={{ border: `2px solid ${theme.palette.primary.main}`, ...props }}>
						{str}
					</TableCell>
				)
			}
			return cols
		},
		[centerCells, columns, data, theme.palette.primary.main]
	)
	const getRows = useCallback(() => {
		let res: any[] = []
		for (let rowIdx = 0; rowIdx < data.length; rowIdx += columns) {
			res.push(<TableRow key={rowIdx}>{getCols(rowIdx)}</TableRow>)
		}
		return res
	}, [columns, data.length, getCols])
	return (
		<Table
			sx={{
				m: 'auto'
			}}
		>
			<TableBody>{getRows()}</TableBody>
		</Table>
	)
}
