import anylogger from '@app/anylogger'
import { Flex, FlexC } from '@app/muiplus'
import { Button } from '@mui/material'
import { MDXRemote } from 'next-mdx-remote'
import Head from 'next/head'
import React, { useCallback, useEffect, useState } from 'react'
import { usePermission } from 'src/services/usePermission'
import { useWebEditorService } from 'src/services/WebEditor/WebEditorService'
import { useWebEditorServiceUI } from 'src/services/WebEditor/WebEditorServiceUI'
import { ErrorBoundary } from './ErrorBoundary'
import { MdxComponents } from './mdxComponents'
import Footer from './Footer'

const log = anylogger('MdxView')

interface MdxViewProps {
	source: string
	data: any
}
export const MdxView = React.forwardRef(function MdxView(props: MdxViewProps, ref: any) {
	let { source, data } = props
	let { title, summary, maxContentWidth } = data

	let maxWidth = '1024px'

	if (maxContentWidth) {
		if (maxContentWidth.toLowerCase() === 'fullscreen') maxWidth = '100%'
		else maxWidth = maxContentWidth
	} else maxWidth = '800px'
	const editor = useWebEditorService()
	const svcUI = useWebEditorServiceUI()
	const [text, setText] = useState('Edit Page')

	const { isWebEditor } = usePermission()
	const editPage = useCallback(
		(props: any) => {
			svcUI.editPage(data.fullPath)
		},
		[data.fullPath, svcUI]
	)
	useEffect(() => {
		if (!data.fullPath) return

		const hasChanges = editor.hasPendingChanges(data.fullPath)
		setText(hasChanges ? 'Continue Editing...' : 'Edit Page')
	}, [data.fullPath, editor])

	const getEditButton = useCallback(() => {
		if (!isWebEditor || !data.fullPath) return null
		return (
			<Flex position="absolute" bottom="0.25em" right="0.25em">
				<Button
					size="small"
					onClick={editPage}
					sx={{
						fontSize: '14px',
						mb: '1em',
						mr: '1em',
						opacity: 0.8
					}}
				>
					{text}
				</Button>
			</Flex>
		)
	}, [data.fullPath, editPage, isWebEditor, text])

	return (
		<ErrorBoundary>
			<Head>
				<title>{title}</title>
				<meta name="description" key="description" content={summary} />
			</Head>
			<FlexC fill gap="0px">
				{/* scroll={false} defers scrolling to the parent, and fill forces the footer to be on the bottom, even for small page content */}
				<FlexC px="0.5em" pb="0.5em" maxWidth={maxWidth} fill scroll={false} mx="auto">
					<div>
						{/* @ts-ignore */}
						<MDXRemote {...source} components={MdxComponents} scroll={false} />
					</div>
				</FlexC>
				<Footer />
			</FlexC>
			{getEditButton()}
		</ErrorBoundary>
	)
})
