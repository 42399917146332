import React from 'react'
import anylogger from '@app/anylogger'
import { Flex, FlexC, FlexR, Text, useCurrentBreakpoint } from '@app/muiplus'
import Link from './Link'
import { Image } from './Image'
import { NextLink } from '@app/uiutils'
import { SvgIcon } from '@mui/material'

const log = anylogger('Footer')

interface FooterProps {}
export default function Footer(props: FooterProps) {
	const year = new Date().getFullYear().toString()
	return (
		<FlexC
			py="0.5em"
			fontSize="16px"
			sx={{
				lineHeight: '1em',
				backgroundColor: 'rgba(0,0,0,0.1)'
			}}
		>
			<Flex f="rw" justifyContent="space-evenly">
				<FooterSection title="Knights of Columbus">
					<a href="https://www.kofc.org" target="_blank" rel="noopener noreferrer">
						Supreme
					</a>
					<a href="https://www.kofc.ab.ca" target="_blank" rel="noopener noreferrer">
						Alberta/Northwest Territories
					</a>
					<a href="https://knightsgear.ca/" target="_blank" rel="noopener noreferrer">
						Knights Gear Canada
					</a>
					<a href="https://knightsgear.com/" target="_blank" rel="noopener noreferrer">
						Knights Gear (Supreme)
					</a>
				</FooterSection>
				<FooterSection title="Catholic Links">
					<a href="https://www.caedm.ca/" target="_blank" rel="noopener noreferrer">
						Catholic Archdiocese of Edmonton
					</a>
					<a href="https://sttheresa.caedm.ca/" target="_blank" rel="noopener noreferrer">
						St. Theresa's Parish
					</a>
					<a href="https://www.cssalberta.ca/" target="_blank" rel="noopener noreferrer">
						Catholic Social Services
					</a>
					{/* <a href="http://grandinmedia.ca/" target="_blank" rel="noopener noreferrer">
						Catholic Alberta
					</a> */}
				</FooterSection>
				<FooterSection title="Social Media">
					{/* <a href="https://www.facebook.com/stpeter7070/" target="_blank" rel="noopener noreferrer">
						<FlexR>
							<Image width="14px" src="/facebook.png" alt="facebook" margin="0px" />
							<Text> St. Peter</Text>
						</FlexR>
					</a> */}
					<a href="https://www.facebook.com/groups/337516243594068" target="_blank" rel="noopener noreferrer">
						<FlexR>
							<Image width="14px" src="/facebook.png" alt="facebook" margin="0px" />
							<Text> AB/NWT</Text>
						</FlexR>
					</a>
					<a href="https://www.facebook.com/KnightsofColumbus" target="_blank" rel="noopener noreferrer">
						<FlexR>
							<Image width="14px" src="/facebook.png" alt="facebook" margin="0px" />
							<Text> Supreme</Text>
						</FlexR>
					</a>
					<a href="https://twitter.com/kofc" target="_blank" rel="noopener noreferrer">
						<FlexR>
							<Image src="/x-logo.png" color="black" width="14px" alt="twitter" margin="0px" />
							<Text>Supreme</Text>
						</FlexR>
					</a>
				</FooterSection>
			</Flex>
			<Flex justifyContent="center">
				<Text mr="20px">Copyright {year}</Text>
				<NextLink href="/OurCouncil/ContactUs">Contact Us</NextLink>
			</Flex>
		</FlexC>
	)
}
interface FooterSectionProps {
	title: string
}
const FooterSection: React.FC<React.PropsWithChildren<FooterSectionProps>> = (props) => {
	const bkp = useCurrentBreakpoint()
	let flexParams: any = { f: 'r', justifyContent: 'space-evenly' }
	if (bkp && bkp == 'mobile') flexParams = { f: 'c', justifyContent: 'center' }

	return (
		<FlexC
			f="c"
			alignItems="center"
			gap="5px"
			sx={{
				mb: '5px'
			}}
		>
			{/* If we want a hard left edge when not in mobile mode, then we should add this flex component to align
			title and children properly.  But, it should not be used in mobile mode or the left edges of each footer
			section will  be different looking off-centre.  For now, we will just not use the Flex comp and have
			all footer section contents centred whether in mobile mode or not.*/}
			{/* <Flex
				f="c"
			> */}
			<b>
				<Text>{props.title}</Text>
			</b>
			{props.children}
		</FlexC>
	)
}
