import { OptimizedImage as Image } from './OptimizedImage'
import { PageLinks } from 'components/PageLinks'
import { IFrame } from 'components/IFrame'
import { Card } from 'components/Card'
import { PageCards } from './PageCards'
import { CardCarousel } from './CardCarousel'
import { Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material'
import { CalendarCards } from './CalendarCards'
import { DataTable } from './DataTable'

export const MdxComponents = {
	table: Table,
	thead: TableHead,
	tbody: TableBody,
	tr: TableRow,
	td: TableCell,
	th: TableCell,
	h1: (props: any) => <h1 align="center" {...props} />,
	h2: (props: any) => <h2 align="center" {...props} />,
	h3: (props: any) => <h3 align="center" {...props} />,
	h4: (props: any) => <h4 {...props} />,
	h5: (props: any) => <h5 {...props} />,
	h6: (props: any) => <h6 {...props} />,
	Image,
	PageLinks,
	IFrame,
	Card,
	PageCards,
	CardCarousel,
	CalendarCards,
	DataTable
}
