import React, { useCallback, useEffect, useMemo, useState } from 'react'
import anylogger from '@app/anylogger'
import { Flex, FlexC, H2 } from '@app/muiplus'
import { PageReferenceData } from '@app/mdxsource'
import { Card } from './Card'
import { adjustDate, formatDateTime, TypesafeBoolean, valueCompare, WebMenu } from '@app/utils'
import { GoogleEvent, IGoogleCalendar } from '@app/googlecalendar'
import { getDateFromUnion } from './calendarUtils'
import { useRouter } from 'next/router'
import { ClientProxy } from '@app/clientserverutils'
import { useWebEditorService } from 'src/services/WebEditor/WebEditorService'
import { string32 } from 'pdfjs-dist/types/src/shared/util'

const log = anylogger('CalendarCards')

export interface CalendarPageLink {
	event: GoogleEvent
	pageData: PageReferenceData
}
interface CalendarCardsProps {
	days: number
}
export function CalendarCards(props: CalendarCardsProps) {
	let { days = 14 } = props
	// even though days is defined as a number, when it is passed as a prop to CalendarCards, it may be a string
	// and if it is a string, adding it to a number will concatenate the string, causing an incorrect value to addDays
	days = Number(days)

	let cardProps = useMemo(() => {
		return { picPos: 'top', picPercent: 50, height: '100%' }
	}, [])
	const [events, setEvents] = useState<Record<string, CalendarPageLink[]>>({})

	const svc = useWebEditorService()
	useEffect(() => {
		const startDate = new Date()
		startDate.setHours(0, 0, 0, 0)

		const endDate = new Date(startDate)
		endDate.setDate(endDate.getDate() + days)

		svc.getEvents(startDate.toISOString(), endDate.toISOString()).then((events) => {
			function sort(a: CalendarPageLink, b: CalendarPageLink) {
				let startA = a.event.start.dateTime
				if (!startA) startA = a.event.start.date + 'T00:00:00'
				let startB = b.event.start.dateTime
				if (!startB) startB = b.event.start.date + 'T00:00:00'
				return valueCompare(startA, startB)
			}
			events.sort(sort)
			let dict: Record<string, CalendarPageLink[]> = {}
			for (let ii = 0; ii < events.length; ii++) {
				const event = events[ii]

				const key = formatDateTime(getDateFromUnion(event.event.start), 'yyyy-mm-dd')

				if (!dict[key]) dict[key] = []
				dict[key].push(event)
			}
			setEvents(dict)
		})
	}, [days, svc])

	const getCards = useCallback(
		(events: CalendarPageLink[]) => {
			if (!events) return null
			return events
				.map((item, idx) => {
					const { event, pageData } = item

					let newPage = { ...pageData }

					newPage.title = `${formatDateTime(getDateFromUnion(event.start), 'HH:nn AM')} - ${event.summary}`
					newPage.summary = event.description || pageData.summary

					return pageData.title && <Card key={idx} {...cardProps} pageData={newPage} mx="0.5em" />
				})
				.filter(TypesafeBoolean)
		},
		[cardProps]
	)
	const getDayEvents = useCallback(() => {
		return Object.keys(events).map((key) => {
			const dayEvents = events[key]
			const cards = getCards(dayEvents)

			return (
				<FlexC mx="auto" key={key} p="0.5em">
					<Flex mx="auto">
						<h2>{formatDateTime(key, 'wdl, mmm dd, yyyy')}</h2>
					</Flex>
					{cards}
				</FlexC>
			)
		})
	}, [events, getCards])

	return (
		<FlexC fill mx="auto">
			<Flex mx="auto">
				<h1>Upcoming Events</h1>
			</Flex>
			{getDayEvents()}
		</FlexC>
	)
}
